<template>
    <Layout>
        <div>
            <h1 class="text-h4 mb-8 d-flex align-center flex-wrap">
                <div>
                    Płatności za usługi
                    <p class="text-body-2 grey--text">pobrane z systemu Vcita</p>
                </div>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    small
                    class="my-2"
                    v-if="$store.getters['auth/userHasPermissions']('payments.fetch_bulk')"
                    @click.stop="fetch_dialog = true"
                >
                    Importuj płatności
                </v-btn>
            </h1>

            <v-sheet
                color="grey lighten-4"
                rounded
                class="pa-4 mb-8"
            >
                <div class="d-flex align-center">
                    <h4 class="text-h6 mt-0">Filtry i sortowanie</h4>
                    <v-spacer></v-spacer>
                    <v-btn
                        icon
                        color="grey"
                        @click="filters_pane_collapsed = !filters_pane_collapsed"
                        :title="filters_pane_collapsed ? 'Rozwiń panel' : 'Zwiń panel'"
                    >
                        <v-icon>{{
                            filters_pane_collapsed
                                ? "mdi-unfold-more-horizontal"
                                : "mdi-unfold-less-horizontal"
                        }}</v-icon>
                    </v-btn>
                </div>
                <v-fade-transition>
                    <div
                        v-if="!filters_pane_collapsed"
                        class="mt-6"
                    >
                        <v-row>
                            <!-- STATUS WYGENEROWANIA FV -->
                            <v-col
                                cols="12"
                                md="4"
                            >
                                <v-select
                                    dense
                                    outlined
                                    label="Status wygen. FV"
                                    :items="[
                                        {
                                            text: 'Obojętnie',
                                            value: 'any'
                                        },
                                        {
                                            text: 'Tylko wygenerowane',
                                            value: 'true'
                                        },
                                        {
                                            text: 'Tylko niewygenerowane',
                                            value: 'false'
                                        }
                                    ]"
                                    :value="filter_invoice_issued"
                                    @change="onFilterInvoiceIssued"
                                    hide-details
                                ></v-select>
                            </v-col>

                            <!-- STATUS WYSŁANIA FV -->
                            <v-col
                                cols="12"
                                md="4"
                            >
                                <v-select
                                    outlined
                                    label="Status wysłania FV"
                                    :items="[
                                        {
                                            text: 'Obojętnie',
                                            value: 'any'
                                        },
                                        {
                                            text: 'Tylko wysłane',
                                            value: 'true'
                                        },
                                        {
                                            text: 'Tylko niewysłane',
                                            value: 'false'
                                        }
                                    ]"
                                    :value="filter_invoice_sent"
                                    @change="onFilterInvoiceSent"
                                    dense
                                    hide-details
                                ></v-select>
                            </v-col>

                            <!-- PO MAILU -->
                            <v-col
                                cols="12"
                                md="4"
                            >
                                <div class="d-flex">
                                    <v-text-field
                                        dense
                                        outlined
                                        label="Wyszukaj po mailu klienta"
                                        :rules="[
                                            v => {
                                                if (!v || v == '' || v.length < 3) {
                                                    return 'Podaj przynajmniej 3 znaki aby wyszukać';
                                                }
                                                return true;
                                            }
                                        ]"
                                        v-model="filter_search"
                                        ref="filter_search"
                                        @keydown.enter="onFilterSearch"
                                    ></v-text-field>
                                    <v-btn
                                        color="primary"
                                        icon
                                        class="ml-1"
                                        @click="onFilterSearch"
                                        title="Wyszukaj"
                                    >
                                        <v-icon>mdi-magnify</v-icon>
                                    </v-btn>
                                    <v-btn
                                        color="error"
                                        v-if="filter_search_applied"
                                        icon
                                        @click="onFilterSearchClear"
                                        title="Wyczyść wyszukiwanie"
                                    >
                                        <v-icon>mdi-backspace</v-icon>
                                    </v-btn>
                                </div>
                            </v-col>

                            <!-- FILTER START DATE -->
                            <v-col
                                cols="12"
                                md="4"
                            >
                                <v-menu
                                    v-model="filter_start_date_menu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :value="filter_start_date"
                                            label="Data wykonania usługi (od)"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            outlined
                                            dense
                                            clearable
                                            @click.native="filter_start_date_menu = true"
                                            @click:clear="filter_start_date = ''"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="filter_start_date"
                                        @input="filter_start_date_menu = false"
                                        no-title
                                        scrollable
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>

                            <!-- FILTER END DATE -->
                            <v-col
                                cols="12"
                                md="4"
                            >
                                <v-menu
                                    v-model="filter_end_date_menu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :value="filter_end_date"
                                            label="Data wykonania usługi (do)"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            outlined
                                            dense
                                            clearable
                                            @click.native="filter_end_date_menu = true"
                                            @click:clear="filter_end_date = ''"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="filter_end_date"
                                        @input="filter_end_date_menu = false"
                                        no-title
                                        scrollable
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>

                            <!-- SORTOWANIE -->
                            <v-col
                                cols="12"
                                md="4"
                            >
                                <v-select
                                    outlined
                                    label="Sortowanie"
                                    :items="[
                                        {
                                            text: 'Data wykonania usługi malejąco',
                                            value: 'service_date::desc'
                                        },
                                        {
                                            text: 'Data wykonania usługi rosnąco',
                                            value: 'service_date::asc'
                                        }
                                    ]"
                                    v-model="sort"
                                    @change="onSort"
                                    dense
                                    hide-details
                                ></v-select>
                            </v-col>
                        </v-row>
                    </div>
                </v-fade-transition>
            </v-sheet>

            <v-data-table
                :headers="[
                    {
                        text: '#',
                        align: 'start',
                        sortable: false,
                        filterable: false,
                        value: 'no'
                    },
                    {
                        text: 'Data real. usługi',
                        align: 'start',
                        sortable: false,
                        filterable: false,
                        value: 'sdate'
                    },
                    {
                        text: 'Klient',
                        align: 'start',
                        sortable: false,
                        filterable: false,
                        value: 'client'
                    },
                    {
                        text: 'Status',
                        align: 'start',
                        sortable: false,
                        filterable: false,
                        value: 'status'
                    },
                    {
                        text: 'FV wygenerowana',
                        align: 'start',
                        sortable: false,
                        filterable: false,
                        value: 'fv_issued'
                    },
                    {
                        text: 'FV wysłana',
                        align: 'start',
                        sortable: false,
                        filterable: false,
                        value: 'fv_sent'
                    },
                    {
                        text: 'Korekta wygenerowana',
                        align: 'start',
                        sortable: false,
                        filterable: false,
                        value: 'corr_issued'
                    },
                    {
                        text: 'Korekta wysłana',
                        align: 'start',
                        sortable: false,
                        filterable: false,
                        value: 'corr_sent'
                    },
                    { text: '', value: 'data-table-expand' }
                ]"
                :items="items"
                item-key="id"
                :item-class="
                    item => {
                        if (item.is_ignored == '1') return 'item-ignored';
                        return '';
                    }
                "
                :options.sync="options"
                :server-items-length="pagination_number_of_items"
                :items-per-page="pagination_items_per_page"
                :loading="items_loading"
                :footer-props="{
                    'disable-items-per-page': true,
                    'items-per-page-options': [pagination_items_per_page, -1]
                }"
                single-expand
                show-expand
                :expanded.sync="expanded_rows"
                show-select
                v-model="selected"
            >
                <template v-slot:item.no="{ index }">
                    <b>{{
                        (pagination_current_page - 1) * pagination_items_per_page + index + 1
                    }}</b>
                </template>

                <template v-slot:item.sdate="{ item }">
                    {{ new Date(item.service_date * 1000).toLocaleDateString() }}
                </template>

                <template v-slot:item.client="{ item }">
                    <div class="py-1">
                        <UserTile
                            :user="{
                                name: item.client_first_name + ' ' + item.client_last_name,
                                email: item.client_email
                            }"
                            details-key="email"
                        />
                    </div>
                </template>

                <template v-slot:item.status="{ item }">
                    <v-chip
                        small
                        color="grey"
                    >
                        <div class="white--text">
                            {{ item.status }}
                        </div>
                    </v-chip>
                </template>

                <template v-slot:item.exclusion="{ item }">
                    <div class="d-flex align-center">
                        <v-simple-checkbox
                            color="primary"
                            :value="item.is_ignored == '1' ? true : false"
                            :ripple="false"
                        ></v-simple-checkbox>
                    </div>
                </template>

                <template v-slot:item.fv_issued="{ item }">
                    <div class="d-flex align-center">
                        <v-simple-checkbox
                            color="primary"
                            :value="item.invoice_issued == '1' ? true : false"
                            :ripple="false"
                        ></v-simple-checkbox>
                        <span
                            class="grey--text"
                            v-if="item.invoice_iss_date != null"
                            >({{
                                new Date(item.invoice_iss_date * 1000).toLocaleDateString()
                            }})</span
                        >
                    </div>
                </template>

                <template v-slot:item.fv_sent="{ item }">
                    <div class="d-flex align-center">
                        <v-simple-checkbox
                            color="primary"
                            :value="item.invoice_sent == '1' ? true : false"
                            :ripple="false"
                        ></v-simple-checkbox>
                        <span
                            class="grey--text"
                            v-if="item.invoice_s_date != null"
                            >({{ new Date(item.invoice_s_date * 1000).toLocaleDateString() }})</span
                        >
                    </div>
                </template>

                <template v-slot:item.corr_issued="{ item }">
                    <div class="d-flex align-center">
                        <v-simple-checkbox
                            color="primary"
                            :value="item.correction_issued == '1' ? true : false"
                            :ripple="false"
                        ></v-simple-checkbox>
                        <span
                            class="grey--text"
                            v-if="item.correction_iss_date != null"
                            >({{
                                new Date(item.correction_iss_date * 1000).toLocaleDateString()
                            }})</span
                        >
                    </div>
                </template>

                <template v-slot:item.corr_sent="{ item }">
                    <div class="d-flex align-center">
                        <v-simple-checkbox
                            color="primary"
                            :value="item.correction_sent == '1' ? true : false"
                            :ripple="false"
                        ></v-simple-checkbox>
                        <span
                            class="grey--text"
                            v-if="item.correction_s_date != null"
                            >({{
                                new Date(item.correction_s_date * 1000).toLocaleDateString()
                            }})</span
                        >
                    </div>
                </template>

                <template v-slot:expanded-item="{ item, headers }">
                    <td :colspan="headers.length">
                        <div class="pa-4">
                            <v-row>
                                <v-col
                                    cols="12"
                                    md="6"
                                >
                                    <v-list-item dense>
                                        <v-list-item-content> Vcita ID: </v-list-item-content>
                                        <v-list-item-content class="align-end font-weight-medium">
                                            {{ item.vcita_id }}
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item dense>
                                        <v-list-item-content>
                                            Tytuł płatności:
                                        </v-list-item-content>
                                        <v-list-item-content class="align-end font-weight-medium">
                                            {{ item.title != null ? item.title : "n/a" }}
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item dense>
                                        <v-list-item-content>
                                            Kwota płatności:
                                        </v-list-item-content>
                                        <v-list-item-content class="align-end font-weight-medium">
                                            <div>
                                                {{ item.amount }}
                                                <span class="grey--text">
                                                    ({{ item.currency }})
                                                </span>
                                            </div>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item dense>
                                        <v-list-item-content>
                                            Metoda płatności:
                                        </v-list-item-content>
                                        <v-list-item-content class="align-end font-weight-medium">
                                            {{ item.payment_method }}
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item dense>
                                        <v-list-item-content>
                                            ID faktury w Fakturowni:
                                        </v-list-item-content>
                                        <v-list-item-content class="align-end font-weight-medium">
                                            {{ item.invoice_id != null ? item.invoice_id : "n/a" }}
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item dense>
                                        <v-list-item-content>
                                            Data utworzenia:
                                        </v-list-item-content>
                                        <v-list-item-content class="align-end font-weight-medium">
                                            {{ new Date(item.c_date * 1000).toLocaleString() }}
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item dense>
                                        <v-list-item-content>
                                            Data ostatniej aktualizacji:
                                        </v-list-item-content>
                                        <v-list-item-content class="align-end font-weight-medium">
                                            {{ new Date(item.u_date * 1000).toLocaleString() }}
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="6"
                                >
                                    <v-list-item dense>
                                        <v-list-item-content>
                                            ID klienta w Vcita:
                                        </v-list-item-content>
                                        <v-list-item-content class="align-end font-weight-medium">
                                            {{ item.vcita_client_id }}
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item dense>
                                        <v-list-item-content> Imię klienta: </v-list-item-content>
                                        <v-list-item-content class="align-end font-weight-medium">
                                            {{ item.client_first_name }}
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item dense>
                                        <v-list-item-content>
                                            Nazwisko klienta:
                                        </v-list-item-content>
                                        <v-list-item-content class="align-end font-weight-medium">
                                            {{ item.client_last_name }}
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item dense>
                                        <v-list-item-content> Email klienta: </v-list-item-content>
                                        <v-list-item-content class="align-end font-weight-medium">
                                            {{
                                                item.client_email != null
                                                    ? item.client_email
                                                    : "n/a"
                                            }}
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item dense>
                                        <v-list-item-content> Adres klienta: </v-list-item-content>
                                        <v-list-item-content class="align-end font-weight-medium">
                                            {{
                                                item.client_address != null
                                                    ? item.client_address
                                                    : "n/a"
                                            }}
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item dense>
                                        <v-list-item-content>
                                            Telefon klienta:
                                        </v-list-item-content>
                                        <v-list-item-content class="align-end font-weight-medium">
                                            {{
                                                item.client_phone != null
                                                    ? item.client_phone
                                                    : "n/a"
                                            }}
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item dense>
                                        <v-list-item-content>
                                            Status wykluczenia:
                                        </v-list-item-content>
                                        <v-list-item-content class="align-end font-weight-medium">
                                            <div class="px-3">
                                                <v-switch
                                                    v-model="item.is_ignored"
                                                    inset
                                                    color="secondary"
                                                    true-value="1"
                                                    false-value="0"
                                                    @change="onItemUpdate(item.id)"
                                                    :disabled="updating"
                                                    :loading="updating"
                                                />
                                            </div>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </div>
                    </td>
                </template>
            </v-data-table>

            <v-slide-y-reverse-transition>
                <v-sheet
                    color="grey lighten-5"
                    rounded
                    class="pa-4"
                    v-if="selected.length > 0"
                    style="position: sticky; bottom: 0px"
                >
                    <div class="d-flex align-center">
                        <div class="font-weight-medium text-caption">Z zaznaczonymi:</div>
                        <v-select
                            dense
                            outlined
                            :items="[
                                {
                                    text: 'Wystaw faktury',
                                    value: 'iss_inv'
                                },
                                {
                                    text: 'Wyślij faktury mailem',
                                    value: 'send_inv'
                                },
                                {
                                    text: 'Wystaw korekty wycofujące',
                                    value: 'iss_corr'
                                },
                                {
                                    text: 'Wyślij korektę mailem',
                                    value: 'send_corr'
                                }
                            ]"
                            v-model="mass_action"
                            hide-details
                            style="max-width: 300px"
                            class="ml-2"
                        ></v-select>

                        <v-btn
                            color="secondary"
                            :loading="mass_action_loading"
                            @click="executeMassAction"
                            class="ml-2"
                        >
                            Wykonaj
                        </v-btn>
                    </div>
                </v-sheet>
            </v-slide-y-reverse-transition>

            <!-- FETCH DIALOG -->
            <v-dialog
                v-model="fetch_dialog"
                scrollable
                max-width="450px"
                persistent
            >
                <v-card>
                    <v-card-title>Importuj płatności</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text
                        style="max-height: 80vh"
                        class="pt-5"
                    >
                        <div class="mb-6">
                            Klikając przycisk poniżej rozpoczniesz import płatności z systemu Vcita.
                            Obiekty zostaną dodane lub zaktualizowane w lokalnej bazie danych. Z
                            uwagi na kosztowność czasową tych operacji pobieranie możliwe jest w
                            paczkach po 128 elementów licząc od najnowszych, do najstarszych. Aby
                            pobrać ręcznie wszystkie dane, należy zwiększać o jeden parametr
                            przesunięcia, aż do momentu, gdy rezultat operacji zwróci komunikat o
                            zerowej liczbie przetworzonych elementów.
                        </div>

                        <v-text-field
                            type="number"
                            outlined
                            label="Parametr przesunięcia"
                            v-model="fetch_dialog_page"
                            ref="fetch_dialog_page"
                            :rules="[
                                v => {
                                    if (!v || v.length === 0) return 'To pole nie może być puste';
                                    if (!/^[0-9]+$/.test(v)) return 'Proszę podać liczbę całkowitą';
                                    if (v < 1) return 'Proszę podać liczbę większą od 0';
                                    return true;
                                }
                            ]"
                        />
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-btn
                            color="grey"
                            text
                            @click="fetch_dialog = false"
                        >
                            Anuluj
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="success"
                            text
                            :loading="fetch_dialog_loading"
                            @click="fetchBulk"
                        >
                            Importuj płatności
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </Layout>
</template>

<script>
export default {
    components: {},

    data() {
        return {
            items: [],
            items_fetched: false,
            items_loading: false,

            filters_pane_collapsed: true,

            options: {
                page: 1
            },
            expanded_rows: [],
            selected: [],
            mass_action: "iss_inv",
            mass_action_loading: false,

            pagination_current_page: 1,
            pagination_number_of_pages: 1,
            pagination_items_per_page: 1,
            pagination_number_of_items: 1,

            filter_search: "",
            filter_search_applied: false,
            filter_invoice_issued: "any",
            filter_invoice_sent: "any",
            filter_start_date: "",
            filter_start_date_menu: false,
            filter_end_date: "",
            filter_end_date_menu: false,

            sort: "service_date::desc",

            fetch_dialog: false,
            fetch_dialog_loading: false,
            fetch_dialog_page: 1,

            updating: false
        };
    },

    watch: {
        options: {
            handler(newValue) {
                if (newValue.page != undefined && newValue.page != this.pagination_current_page) {
                    this.onPaginate(newValue.page);
                }
            },
            deep: true
        },
        filter_start_date() {
            this.onPaginate(1);
        },
        filter_end_date() {
            this.onPaginate(1);
        }
    },

    methods: {
        onPaginate(page) {
            this.pagination_current_page = page;
            this.options.page = page;
            this.fetchData();
        },
        onFilterInvoiceIssued(v) {
            this.filter_invoice_issued = v;
            this.onPaginate(1);
        },
        onFilterInvoiceSent(v) {
            this.filter_invoice_sent = v;
            this.onPaginate(1);
        },
        onFilterSearch() {
            if (!this.$refs.filter_search.validate(true)) return;
            this.filter_search_applied = true;
            this.onPaginate(1);
        },
        onFilterSearchClear() {
            this.filter_search = "";
            this.filter_search_applied = false;
            this.$refs.filter_search.resetValidation();
            this.onPaginate(1);
        },

        onSort() {
            this.fetchData();
        },

        fetchData() {
            return new Promise(async (resolve, reject) => {
                if (this.items_loading) return resolve(false);
                this.items_loading = true;

                try {
                    const [sort_field, sort_dir] = this.sort.split("::");

                    const QS = [
                        `page=${this.pagination_current_page}`,
                        `filter_invoice_issued=${this.filter_invoice_issued}`,
                        `filter_invoice_sent=${this.filter_invoice_sent}`,
                        `sort_field=${sort_field}`,
                        `sort_dir=${sort_dir}`
                    ];

                    if (this.filter_search_applied) {
                        QS.push(`filter_search=${encodeURIComponent(this.filter_search)}`);
                    }

                    if (this.filter_start_date != "") {
                        const d = new Date(this.filter_start_date);
                        d.setHours(0);
                        d.setMinutes(0);
                        d.setSeconds(0);
                        d.setMilliseconds(0);

                        QS.push(`filter_start_date=${Math.round(d.getTime() / 1000)}`);
                    }

                    if (this.filter_end_date != "") {
                        const d = new Date(this.filter_end_date);
                        d.setHours(23);
                        d.setMinutes(59);
                        d.setSeconds(59);
                        d.setMilliseconds(999);

                        QS.push(`filter_end_date=${Math.round(d.getTime() / 1000)}`);
                    }

                    const r = await this.$axios.$get(`/payments/?${QS.join("&")}`);
                    // console.log(r);

                    this.items_fetched = true;
                    this.items = r.payments;
                    this.pagination_current_page = parseInt(r.pagination.current_page);
                    this.options.page = r.pagination_current_page;
                    this.pagination_number_of_pages = parseInt(r.pagination.number_of_pages);
                    this.pagination_items_per_page = parseInt(r.pagination.items_per_page);
                    this.pagination_number_of_items = parseInt(r.pagination.number_of_items);
                } catch (err) {
                    return reject(err);
                }

                this.items_loading = false;
                return resolve(true);
            });
        },

        // bulk fetch
        async fetchBulk() {
            if (this.fetch_dialog_loading || !this.$refs.fetch_dialog_page.validate(true)) return;
            this.fetch_dialog_loading = true;

            try {
                const r = await this.$axios.$post(
                    "/payments/fetch",
                    {
                        page: this.fetch_dialog_page
                    },
                    {
                        timeout: 0
                    }
                );

                this.items = [];
                this.pagination_current_page = parseInt(1);
                this.options.page = 1;

                this.fetchData();

                this.$message({
                    type: "success",
                    msg: `Dane zostały zaimportowane. Przetworzonych elementów: ${r.metadata.processed}; w tym dodanych: ${r.metadata.added}, zaktualizowanych: ${r.metadata.updated}`
                });

                this.fetch_dialog = false;
            } catch (err) {
                console.error(err);
            }

            this.fetch_dialog_loading = false;
        },

        async executeMassAction() {
            if (this.mass_action_loading) return;
            this.mass_action_loading = true;

            const ids = this.selected.map(item => item.id);
            try {
                const ACTION_URLS = {
                    iss_inv: "issue-invoices",
                    send_inv: "send-invoices-by-email",
                    iss_corr: "issue-corrections",
                    send_corr: "send-corrections-by-email"
                };

                await this.$axios.$post(`/payments/${ACTION_URLS[this.mass_action]}`, {
                    payments: ids
                });

                this.fetchData();

                this.$message({
                    type: "success",
                    msg: "Operacja wykonana pomyślnie"
                });
            } catch (err) {
                console.error(err);
            }

            this.mass_action_loading = false;
        },

        async onItemUpdate(item_id) {
            if (this.updating) return;
            const ix = this.items.findIndex(item => item.id == item_id);
            if (ix === -1) return;

            this.updating = true;

            try {
                const r = await this.$axios.$put(`/payments/${item_id}`, this.items[ix]);

                this.items.splice(ix, 1, r.payment);
            } catch (err) {
                console.error(err);
            }

            this.updating = false;
        }
    },

    async mounted() {
        if (!this.$store.state.initial_layout_set) return;

        await this.fetchData();
    },

    metaInfo: {
        title: "Płatności"
    }
};
</script>

<style lang="scss">
.item-ignored {
    position: relative;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background-color: rgba(200, 200, 200, 0.6);
        backdrop-filter: grayscale(100%);
        pointer-events: none;
    }
}
</style>
